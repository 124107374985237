//== Labels
//
//##

//** Default label background color
$label-default-bg:           $gray-dark !default;
//** Primary label background color
$label-primary-bg:            $brand-primary !default;

$label-second-bg:             $brand-second !default;

$label-white-bg:             white !default;
$label-white-color:           $gray-darker;
//** Success label background color
$label-success-bg:            $brand-success !default;
//** Info label background color
$label-info-bg:               $brand-info !default;
//** Warning label background color
$label-warning-bg:            $brand-warning !default;
//** Danger label background color
$label-danger-bg:             $brand-danger !default;



//** Default label text color
$label-color:                 #fff !default;
//** Default text color of a linked label
$label-link-hover-color:      #fff !default;
