body {
    font-family: $font-family-base;
    font-size: $font-size-base;
    line-height: $line-height-base;
    color: black;
    background: white;
  }
  
  p{
    @media (max-width:1280px) {
         font-size:14px
    }
   }
  
  .font2{
    font-family: $headings-font-family;
  }
  
  .page-header {
      margin: 50px 0;
      @extend .title_center;
  }

.bg-1{
    @media (max-width:$screen-sm-min) {
        h1{
            text-shadow: 2px 2px 1px black,
                0px 0px 10px black;
        }
    }
}
  
  .btn, a, button {
      transition: .5s;
              outline-style: none;
          outline-width: 0;
          outline-color: transparent;
  
      &:hover,
      &:focus,
      &:active {
          outline-style: none;
          outline-width: 0;
          outline-color: transparent;
      }
  }
  
  .btn{
      line-height:1.5 !important;
  }
  
  .footer {
      text-align: center;
      background-color: white;
      font-size: $font-size-base;
      color: black;
      min-height: 25rem;
      display: flex;
      align-items: center;
      border-top:solid 1px $brand-primary;
  
      a {
          color: black;
  
          &:hover {
              color: black;
          }
      }
      
        .footer_address{
          color:black;
      }
      @media(min-width: $screen-sm-min) {
          text-align: left;
      }
  }
  
  ////////////// boutons
  
  .btn-fleche {
      @extend .btn-accueil;
      background: $brand-primary;
      white-space: normal;
      position: relative;
      padding: 10px 50px;
      border: 2px solid;
      color: white;
      font-weight: bold;
      border-radius: 50px;
      border: solid 1px $brand-primary;
      //margin-bottom: 50px;
      -webkit-transition: all .2s ease-out;
      transition: all .2s ease-out;
  
  
      &:hover {
          background: white;
          color: $brand-primary;
          border-radius: 50px;
          border: solid 1px $brand-primary;
          padding-right: 70px;
      }
  
      &::after {
          position: absolute;
          margin-left: 20px;
          content: "\f061";
          font-family: fontAwesome;
          color: $brand-primary;
          -webkit-transition: all .8s ease-out .0s;
          transition: all .8s ease-out .0s;
      }
  }
  
  .btn-fleche-2 {
      @extend .btn-accueil;
      background: white;
      white-space: normal;
      position: relative;
      padding: 10px 50px;
      border: 2px solid;
      color: $brand-primary;
      font-weight: bold;
      border-radius: 50px;
      border: solid 1px $brand-primary;
      //margin-bottom: 50px;
      -webkit-transition: all .2s ease-out;
      transition: all .2s ease-out;
  
      &:hover {
          background: $brand-primary;
          color: white;
          border-radius: 50px;
          border: solid 1px $brand-primary;
          padding-right: 70px;
          -webkit-transition: all .2s ease-out;
          transition: all .2s ease-out;
      }
  
      &::after {
          position: absolute;
          margin-left: 20px;
          content: "\f061";
          font-family: fontAwesome;
          color: white;
          -webkit-transition: all .8s ease-out .0s;
          transition: all .8s ease-out .0s;
      }
  }
  
  .btn-fleche-inverse {
      @extend .btn-fleche;
      border: 2px solid;
      color: $brand-primary;
      border: solid 1px $brand-primary;
  
      &:hover {
          background: $brand-primary;
          color: white;
  
      }
  
      &::after {
          color: white;
      }
  }
  
  .btn-perso {
      @extend .btn-accueil;
      background: $brand-primary;
      color: white;
      border: solid 2px white;
      border-radius: 50px;
      padding: 15px 50px;
      margin: 15px auto;
  
      &:hover {
          background: white;
          color: $brand-primary;
          border-radius: 50px;
          border: solid 2px $brand-primary;
      }
  
      .fa {
          margin-right: 10px;
      }
  }
  
  .btn-perso-inverse {
      background: white;
      color: $brand-primary;
      border: solid 1px $brand-primary;
      border-radius: 50px;
      padding: 15px 50px;
      margin: 15px auto;
  
      .fa {
          margin-right: 10px;
      }
  }
  
  /* ==========================================================================
      Global
      ========================================================================== */
      .title_left {
          @extend .text-left;
          margin: 0 0 20px 0;
          padding: 0 0 25px 0;
          position: relative;
          color: $brand-primary;
          text-transform: uppercase;
          line-height: 1.5;
          font-family: 'Josefin Sans', sans-serif;
          font-weight:bolder;
      
          small {
              display: block;
              line-height: 1.5;
          }
      
          &::after {
              content: '';
              position: absolute;
              bottom: 0;
              left: 0;
              width: 4rem;
              height: 4px;
              background-color: $brand-second;
          }
      }
      
      .title_left_2 {
          @extend .text-left;
          @extend .title_left;
          color: white;
          font-weight: bolder;
      
          &::after {
              background-color: black;
          }
      }
      
      
      .title_center {
          @extend .text-center;
          margin: 0 0 20px 0;
          padding: 0 0 25px 0;
          position: relative;
          color: $brand-primary;
          line-height: 1.5;
          font-family: 'Josefin Sans', sans-serif;
      
          small {
              display: block;
              line-height: 1.5;
          }
      
          &::after {
              content: '';
              position: absolute;
              bottom: 0;
              left: 50%;
              width: 4rem;
              height: 2px;
              transform: translateX(-50%);
              background-color: $brand-primary;
          }
      }

        .title_center_2 {
          @extend .text-center;
          margin: 0 0 20px 0;
          padding: 0 0 25px 0;
          position: relative;
          color: white;
          line-height: 1.5;
          font-family: 'Josefin Sans', sans-serif;
      
          small {
              display: block;
              line-height: 1.5;
          }
      
          &::after {
              content: '';
              position: absolute;
              bottom: 0;
              left: 50%;
              width: 4rem;
              height: 2px;
              transform: translateX(-50%);
              background-color: white;
          }
      }
      
      
      
      .frame-1{
          padding:20px;
          margin:20px;
              
          @media (max-width:$screen-xs-min) {
                padding:10px;
                margin:10px;
             }
      }
      
      .frame-2{
          padding:20px;
            
          @media (max-width:$screen-xs-min) {
                padding:10px;
             }
      }
  
      .frame-3{
          margin:20px;
            
          @media (max-width:$screen-xs-min) {
                margin:10px;
             }
      }
  
      .smap {
    
        .map {
            padding-bottom: 500px;
        }
    
        .block1 {
            padding: 3%;
           color: white;
            background: $brand-primary;
        }
    }
  
    .img-rounded{
            border-radius: 15px;
    }
  
    .blockquote-2 {
      padding: 15px 30px;
      margin: 0 0 30px;
      font-size: 16px;
      border-left: 5px solid $brand-primary;
      margin-left:25px;
      background-color: white;
      border-radius:0 15px 15px 0px;
      //   box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
  }

.reverse{
    @media (max-width:$screen-sm-min) {
      flex-direction: column-reverse ;
    }
}

@media (min-width: $screen-md-min) {
    .figure__compo-1 {
      position: relative;
      @include padding(1rem);
      height: 40rem;
      max-height: 100%;
      width: 40rem;
      max-width: 100%;
  
      > img {
        position: absolute;
        object-fit: cover;
      }
  
      > img:nth-child(1) {
        top: 0;
        left: 0;
        width: 70%;
        height: 55%;
      }
  
      > img:nth-child(2) {
        bottom: 0;
        right: 0;
        width: 70%;
        height: 55%;
      }
    }
  }

  @media (max-width: 992px) { .rellax { transform: translate3d(0,0,0) !important; } }


  
  //.nav>li>a {
      //padding: 10px 15px;
  
  //	@media (max-width:1280px) {
      //	font-size: 12px;
      //	padding: 10px 10px;
      //}
  //}
  
  //////////////////////////////////////////////////////
  //////////////////////////////////////////////////////
  //////////////////////////////////////////////////////

  
  .container-bricks-1 {
	padding: 1rem;

	@media(min-width: 970px) {
		// display: flex;
		// flex-flow: row wrap;
	}

	@media(min-width: 1170px) {
		padding: 1.5rem;
	}


.brick {
	padding: .5rem;
	// width: 100%;

	@media(min-width: 1170px) {
		padding: 1rem;
	}

	>a {
		display: block;
		height: 30rem;
		border-radius: .5rem;
		overflow: hidden;
		// box-shadow: 0 1rem 3rem -1rem rgba(black, .7);
	}

	.theme-1 {
		background: url(../images/charte/gym1.jpg) no-repeat center;
		background-size: cover;
	}

	.theme-2 {
		background: url(../images/charte/GRS1.jpg) no-repeat center;
		background-size: cover;
	}

	.theme-3 {
		background: url(../images/charte/gym2.jpg) no-repeat center;
		background-size: cover;
	}
    
	.theme-4 {
		background: url(../images/charte/gym3.jpg) no-repeat center;
		background-size: cover;
	}

	.theme-5 {
		background: url(../images/charte/pilates.jpg) no-repeat center;
		background-size: cover;
	}

	.theme-6 {
		background: url(../images/charte/aqua.jpg) no-repeat center;
		background-size: cover;
	}

    .theme-7 {
		background: url(../images/charte/peinture.jpg) no-repeat center;
		background-size: cover;
	}

    .theme-8 {
		background: url(../images/charte/petanques.jpg) no-repeat center;
		background-size: cover;
	}

    .theme-9 {
		background: url(../images/charte/tricot.jpg) no-repeat center;
		background-size: cover;
	}

    .theme-10 {
		background: url(../images/charte/clair.jpg) no-repeat center;
		background-size: cover;
	}

    .theme-11 {
		background: url(../images/charte/music.jpg) no-repeat center;
		background-size: cover;
	}

}

.brick-inner {
	position: relative;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: relative;
    color: white;
    padding: 5%;

	&::after {
		content: '';
		position: absolute;
		z-index: 0;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(black, .5);
		transition: ease 1s;
	}

	&:hover::after {
		background-color: rgba(black, .8);
	}
}

.brick-title {
	text-align: center;
	position: relative;
	z-index: 1;
	font-size: 2.5rem;
	padding: 1.5rem;
	text-transform: uppercase;
	font-weight: 600;
	color: white;
	line-height: 1.2;

	@media(min-width: 750px) {
		font-size: 2rem;
	}
}

.brick-btn {
	position: relative;
	z-index: 1;
	text-transform: uppercase;
    font-weight: 300;
	color: white;
}

.brick-1 {

	@media(min-width: 970px) {
		// width: 60%;
	}
}

.brick-2 {

	@media(min-width: 970px) {
		// width: 40%;
	}
}

  }

////////////////////////

.border-p{
    border: solid 10px #f5f5f5;
}

.s1{
    .block1{
        background: url(../images/charte/1909.jpg) no-repeat center;
        background-size: cover;
        min-height: 30vh;
    }

     .block2{
        background: url(../images/charte/1920.jpg) no-repeat center;
        background-size: cover;
        min-height: 30vh;
    }
}

.s3{
    margin-top: -300px;

    @media (max-width:$screen-xs-min) {
        margin-top: -0px;
    }

    @media (max-width:$screen-sm-min) {
        margin-top: -0px;
    }
}

.detail{
    .well{
        border: solid 1px $brand-primary;
    }

}

.border{
     border: solid 1px $brand-primary;
}