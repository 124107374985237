//-- Backgrounds

.bg-1 {
  @include background2("../images/charte/header1.jpg", #000, 0.3);
}

.bg-2 {
  @include background2("../images/charte/header2.jpg", #000, 0.3);
}

.bg-gradient-1 {
  @include background("", linear-gradient(45deg, #e66465, #9198e5));
}

.bg-marielouise {
  @include background2("https://picsum.photos/id/1018/1920/1280", #fff, 0);
  border: 3vw solid white;
}

.bg-white {
  background-color: white;
}

.bg-second {
  background-color: $brand-second;
}

.bg-light {
  background-color: $gray-light;
}

.bg-lighter {
  background-color: $gray-lighter;
}

.bg-gray {
  background-color: $gray;
}

.bg-dark {
  background-color: $gray-dark;
}

.bg-darker {
  background-color: $gray-darker;
}

.bg-danger {
  background-color: $brand-danger;
}

.bg-success {
  background-color: $brand-success;
}

.bg-primary {
  background-color: $brand-primary;
}

.bg-overlay {
  position: relative;
  > img {
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  > .bg-overlay__filter {
    &::after {
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.4);
      background-image: linear-gradient(
        to right,
        rgba(0, 0, 0, 0.8),
        rgba(0, 0, 0, 0) 60%
      );
    }
  }
  > .bg-overlay__content {
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}
